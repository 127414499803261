import React, {useEffect} from "react";
import {navigate} from "gatsby";
import {Helmet} from "react-helmet";

import Favicon from "../images/Favicon.png";

const NotFoundPage = () => {
    useEffect(() => {
        typeof window !== "undefined" && window.gtag("event", "source_qr_code");
        navigate('/');
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
            </Helmet>
        </>
    )
}

export default NotFoundPage;
